import logo from './images/logo.png';
import search from './images/search.svg';
import Item from './Item';
import './main.css';
import React, { useState, useRef, useEffect } from 'react';
import ReactTwitterFollowButton from './Twitter'

function App() {
  const inputRef = useRef(null);
  const [data] = useState(require('./font-data.json'));
  const [displayedData, setDisplayedData] = useState([]);
  const [loadMoreCount, setLoadMoreCount] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Filter data based on searchTerm
    const filteredData = data.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Update displayedData based on filtered data and loadMoreCount
    setDisplayedData(filteredData.slice(0, loadMoreCount));
  }, [searchTerm, loadMoreCount, data]);


  const handleClick = () => {
    inputRef.current.focus();
  };

  const handleLoadMore = () => {
    setLoadMoreCount(prevCount => prevCount + 20);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setLoadMoreCount(20); // Reset loadMoreCount when search term changes
  };

  return (
    <div className="App" style={{ paddingBottom:100 }}>
      <header className="App-header">
        <img alt='logo' src={logo} className='logo' />
        <p>Easily get Adobe Fonts for free on TypeCrack.com!</p>
      </header>
      <div className='input-wrapper' onClick={handleClick}>
          <img src={search} alt='search-icon' />
          <input
            ref={inputRef}
            placeholder={`Search among ${data.length} fonts...`}
            onChange={handleSearchChange}
            value={searchTerm}
          />
        </div>
        <p style={{ display:'flex', marginBottom:150, flexDirection:'row', alignItems:'center' }}>Made with ❤️ by <div style={{ marginLeft:10 }}><ReactTwitterFollowButton twitterAccount={'nelsorex'} /></div></p>
      <div className='items'>
        {displayedData.map((item, index) => (
          <Item key={index} files={item.files} name={item.name} format={item.format} weights={item.weights} />
        ))}
      </div>
      {displayedData.length < data.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())).length && (
        <button style={{ marginTop:50 }} onClick={handleLoadMore}>Load More</button>
      )}
    </div>
  );
}

export default App;
