import React, { useState, useEffect, useRef } from 'react';
import download from './images/download.svg';
import './item.css';
import './main.css';
import loadingSvg from './images/loading.svg';

// function getTextWidth(text, font) {
//   const canvas = document.createElement('canvas');
//   const context = canvas.getContext('2d');

//   context.font = font || getComputedStyle(document.body).font;
//   console.log(context.measureText(text).width);
//   return context.measureText(text).width;
// }

function getTextWidth(text, styles) {
  var isObjectJSON = function (obj) {
    return obj && typeof obj === 'object' && !Array.isArray(obj);
  };

  var element = document.createElement('div');
  if (isObjectJSON(styles)) {
    var styleKeys = Object.keys(styles);
    for (var i = 0, n = styleKeys.length; i < n; ++i) {
      element.style[styleKeys[i]] = styles[styleKeys[i]];
    }
  }
  element.style.display = 'inline-block';
  element.innerHTML = text;
  document.body.appendChild(element);
  var width = element.offsetWidth;
  document.body.removeChild(element);
  return width;
};


function Item({ files, name, format, weights }) {
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null); // Store URL instead of Image object
  const [error, setError] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  let file = files[0];

  for (const a of files) {
    if (a.toLowerCase().includes('regular')) {
      file = a;
    }
  }

  useEffect(() => {
    // Reset preview when component re-renders (e.g., due to search changes)
    setPreviewUrl(null);
    setShowPreview(false);
    setIsLoading(false);
    setError(null);
  }, [files, name]); // Add dependencies to trigger reset

  const loadPreview = async () => {
    setShowPreview(true);
    setIsLoading(true);

    try {
      const response = await fetch('/.netlify/functions/get-thumbnail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ path: file })
      });

      if (!response.ok) {
        const text = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setPreviewUrl(imageUrl); // Store the URL directly
      setIsLoading(false);

    } catch (err) {
      setIsLoading(false);
      setError(`Couldn't fetch font preview: ${err.message}`);
    }
  };

  useEffect(() => {
    // Clean up URL when component unmounts or preview URL changes
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]); // Correct dependency


  const [showLinks, setShowLinks] = useState(false);
    const linksContainerRef = useRef(null);

    const toggleLinks = () => {
        setShowLinks(!showLinks);
    };

    useEffect(() => {
        // Event listener to close the links dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (linksContainerRef.current && !linksContainerRef.current.contains(event.target)) {
                setShowLinks(false);
            }
        };

        if (showLinks) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showLinks]);

    const handleDownloadClick = async (file, index) => {
      const linkElement = document.querySelector(`span[lol="${file}${index}"]`);
      const previous = linkElement.innerHTML
  
      if (linkElement) {
        // Display loading indicator and hide link text
        linkElement.innerHTML = `<img src='loading-white.svg' alt='loading-icon'/>`;
  
        try {
          const response = await fetch('/.netlify/functions/get-download', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ path: file })
          });
  
          if (!response.ok) {
            const text = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
          }
  
          const data = await response.json();
          const downloadUrl = data.downloadLink;
  
          if (downloadUrl) {
            // Open the download link in a new tab
            window.open(downloadUrl, '_blank');
            linkElement.innerHTML = previous;
          } else {
            // Handle cases where the response doesn't contain a valid download URL
            linkElement.innerHTML = "Download failed"; // Or display a more user-friendly error message
            console.error("Invalid download URL received from server:", data);
          }
  
        } catch (error) {
          console.error("Error during download:", error);
          linkElement.innerHTML = "Download failed"; // Or display a more user-friendly error message
        }
      }
    };

  return (
    <div className="item">
      <div className="font-display-container">
        {!showPreview && <button style={{ alignSelf: 'center', justifySelf: 'center' }} className='small-button' onClick={loadPreview}>Load Preview</button>}
        {isLoading && <img style={{ alignSelf: 'center', justifySelf: 'center' }} src={loadingSvg} alt="Loading..." />}
        {error && <p className="error-message">{error}</p>}
        {previewUrl && <img style={{ maxWidth:380, maxHeight:224, alignSelf: 'center', justifySelf: 'center' }} src={previewUrl} alt={name} />} {/* Use previewUrl directly */}
      </div>
      <div className="item-info-container">
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
          <div className='container'>
            <h3 className={getTextWidth(name.replace('Variable', '').replace('variable', '')) > 140 ? 'animated' : ''}>{name.replace('Variable', '').replace('variable', '')}</h3>
          </div>
          <p className='format-p'>{format}</p>
          <p>{name.toLowerCase().includes('variable') ? 'Variable' : `${weights} weights`}</p>
        </div>
        <div className="download-container" ref={linksContainerRef}> {/* Added container for links */}
          <button className='download-button' onClick={toggleLinks}>
            <img src={download} alt='download-icon' />
          </button>
          {showLinks && (
            <div className="download-links">
              {files.map((file, index) => (
                <span
                  style={{ margin: 0, alignContent: 'center', alignItems: 'center', display: 'flex', cursor:'pointer' }}
                  key={index}
                  lol={`${file}${index}`}
                  onClick={() => handleDownloadClick(file, index)} // Call handleDownloadClick on click
                >
                  <img src={download} width={20} height={20} style={{ marginRight: 10 }} alt='download-icon' />
                  {file.split('/').pop()}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Item;
